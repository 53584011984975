import axios from "axios";

export const sendSurveyDataToAirtable = (ReviewData, tableId) => {
  const data = {
    records: [{ fields: { ...ReviewData } }]
  }

  return axios.post(
    `https://api.airtable.com/v0/app60w4gzfFKx6YFi/${tableId}`, data, { headers: { Authorization: `Bearer pat5xSgYHcT1q7MYm.29bcf52f755a07d5a31bf1ae0c8dcd1a937b3316fc196aa458efe4f8c7bb71db` } }
  );
}

export const getAllAirtableData = (tableId) => {
  return axios.get(
    `https://api.airtable.com/v0/app60w4gzfFKx6YFi/${tableId}`, { headers: { Authorization: `Bearer pat5xSgYHcT1q7MYm.29bcf52f755a07d5a31bf1ae0c8dcd1a937b3316fc196aa458efe4f8c7bb71db` } }
  );
}