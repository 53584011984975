export function Loading_ring() {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export function Loading_ellipsis({ big = false, color }) {
  return (
    <div className="loading_ellipsis">
      <span style={big ? { fontSize: '90px', lineHeight: "0px", color: color ? color : 'inherit' } : {}}>.</span>
      <span style={big ? { fontSize: '90px', lineHeight: "0px", color: color ? color : 'inherit' } : {}}>.</span>
      <span style={big ? { fontSize: '90px', lineHeight: "0px", color: color ? color : 'inherit' } : {}}>.</span>
    </div>
  )
}

export function Loading_circle() {
  return (
    <div className="loading_circle"></div>
  )
}