import { useEffect, useState } from "react";
import styles from "./question.module.scss";
import { HandySvg } from "handy-svg";
import { QuestionSurvey } from "../survey/survey";
import getCommonLocales from "../../locales/locales_common";
import { useRouter } from "next/router";
import Image from "next/image";

const Question = () => {
	const [openQuestionForm, setOpenQuestionForm] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const { locale, route } = useRouter();
	const dictionary = { ...getCommonLocales(locale) };

	useEffect(
		() => {
			setTimeout(() => {
				setShowButton(true);
			}, 5000);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<>
			{showButton && !openQuestionForm && (
				<button aria-label={"open qustions form"} className={styles.questionBtn} onClick={() => setOpenQuestionForm(true)}>
					<HandySvg name="question" src={"/survey/question.svg"} width={30} height={29.5} />
					<span>{dictionary["support"] || "Support"}</span>
				</button>
			)}
			{openQuestionForm && <QuestionSurvey close={() => setOpenQuestionForm(false)} dictionary={dictionary} page={route} />}
		</>
	);
};

export default Question;
