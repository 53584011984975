import Image from "next/image";
import style from "./SocialMediaAuth.module.scss";
import * as ga from "../../../lib/ga";
import facebookIco from "../../../public/authModal/facebook.png";
import googleIco from "../../../public/authModal/google.png";
import appleIco from "../../../public/authModal/apple.png";
import yandexIco from "../../../public/authModal/yandex.png";
import mailIco from "../../../public/authModal/mail.png";
import { HandySvg } from "handy-svg";

const content = {
	signIn: {
		links: [
			{ id: 4, gaName: "Yandex", name: "Войти c Яндекс", url: "/auth/yandex/", image_url: yandexIco, alt_text: "Yandex icon", ruOnly: true },
			{ id: 5, gaName: "Mail.ru", name: "Войти c Mail.ru", url: "/auth/mailru/", image_url: mailIco, alt_text: "Mail.ru icon", ruOnly: true },
			// { id: 1, gaName: "Facebook", name: "Log In with Facebook", url: "/auth/facebook/", image_url: facebookIco, alt_text: "Facebook icon", notRu: true },
			{ id: 2, gaName: "Google", name: "Log In with Google", url: "/auth/google/", image_url: googleIco, alt_text: "Google icon" },
			{ id: 3, gaName: "Apple", name: "Log In with Apple", url: "/auth/apple-id/", image_url: appleIco, alt_text: "Apple icon" },
		],
		btnText: "sign_in_with_email",
	},
	signUp: {
		links: [
			{ id: 4, gaName: "Yandex", name: "Регистрация c Яндекс", url: "/auth/yandex/", image_url: yandexIco, alt_text: "Yandex icon", ruOnly: true },
			{ id: 5, gaName: "Mail.ru", name: "Регистрация c Mail.ru", url: "/auth/mailru/", image_url: mailIco, alt_text: "Mail.ru icon", ruOnly: true },
			// { id: 1, gaName: "Facebook", name: "Sign Up with Facebook", url: "/auth/facebook/", image_url: facebookIco, alt_text: "Facebook icon", notRu: true },
			{ id: 2, gaName: "Google", name: "Sign up with Google", url: "/auth/google/", image_url: googleIco, alt_text: "Google icon" },
			{ id: 3, gaName: "Apple", name: "Sign up with Apple", url: "/auth/apple-id/", image_url: appleIco, alt_text: "Apple icon" },
		],
		btnText: "sign_up_with_email",
	},
};

function SocialMediaAuth({ action, dictionary, changeMethodHandler = () => {}, locale, testValue, serviceName = "" }) {
	const { links, btnText } = content[action];

	const handleSocialClick = (e, name) => {
		e.preventDefault();
		ga.event({
			action: action === "signIn" ? "Sign in" : "Sign up",
			params: {
				page: serviceName,
				method: name,
				testValue: testValue?.length > 0 ? testValue : "",
			},
		});
		window.location.href = e.currentTarget.href;
	};

	const queryUrl = window.location.href;

	return (
		<div className={style.container}>
			{links.map((link) => {
				if (link.ruOnly && locale !== "ru") return null;

				if (link.notRu && locale === "ru") return null;

				return (
					<a className={style.link} href={link.url + `?redirect_url=${queryUrl}`} key={link.alt_text} onClick={(e) => handleSocialClick(e, link.gaName)}>
						<Image src={link.image_url} width={25} height={25} alt={link.alt_text} className={style.image} />
						<span className={style.linkText}>{dictionary[link.name] || link.name}</span>
					</a>
				);
			})}
			<span className={style.separator}>{dictionary["or"]}</span>
			<button onClick={changeMethodHandler} className={style.emailBtn} id="email-btn">
				{dictionary[btnText]}
				<HandySvg src={"/illustration_service/next.svg"} width={20} height={20} fill={"transparent"} />
			</button>
		</div>
	);
}

export default SocialMediaAuth;
