import { useState, useCallback, useEffect } from "react";
import { HandySvg } from "handy-svg";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import style from "./header.module.scss";
import getCommonLocales from "../../locales/locales_common";
import getRemoveBgPageLocales from "../../locales/locales_remove_bg";
import { useStore } from "../../stores/use_global_state.js";
import { bodyActiveScroll, bodyHiddenScrollForHeader } from "../../utils/bodyToggleHiddenScroll";
import * as ga from "../../lib/ga";
import AuthModal from "../AuthModal/AuthModal.jsx";
import LanguageChanger from "./languageChanger/languageChanger.jsx";
import { features } from "../main_page/EntryBlock/constants.js";

const SubMenuWrapperItem = ({ dictionary, setActiveToolItem, title, list, type }) => {
	return (
		<ul>
			<h3 className={style.subMenuTitle}>
				<HandySvg src={`/header/${type}.svg`} width={24} height={24} fill={"transparent"} />
				<span dangerouslySetInnerHTML={{ __html: `${title}` }} />
			</h3>
			{list.map((item) => (
				<li key={item.name} className={style.subMenuItem} onMouseEnter={() => setActiveToolItem(item)}>
					<Link href={`${item.link_url}`} className={style.contentLink}>
						{dictionary[item.name] || item.name}
					</Link>
				</li>
			))}
		</ul>
	);
};

const SubMenuImg = ({ activeToolItem, dictionary }) => {
	return (
		<div className={style.imageWrapper}>
			<Image className={style.item_img} src={`/header/service/${activeToolItem.description}.png`} alt={activeToolItem.name} width={500} height={320} quality={95} loading="lazy" />
			{activeToolItem.result && (
				<>
					<span className={style.original}>{dictionary["Original"]}</span>
					<span className={style.result}>{dictionary["Result"]}</span>
				</>
			)}
			{activeToolItem.prompt && (
				<div className={style.prompt}>
					<span>{dictionary["prompt"]}</span> {dictionary[activeToolItem.prompt] || activeToolItem.prompt}
				</div>
			)}
		</div>
	);
};

function Header({ setShowAdaptiveBtn, themeClassName }) {
	const removerToolList = features.filter((item) => item.isRemover);
	const imagesToolList = features.filter((item) => !item.isRemover);
	const [menuIsVisible, setMenuIsVisible] = useState(false);
	const [activeTab, setActiveTab] = useState("");
	const { locale, pathname } = useRouter();
	const { state } = useStore();
	const authUser = state?.load && state?.userInfo !== null && state?.accountData;
	const notAuthUser = state?.load && state?.userInfo === null;
	const dictionary = { ...getCommonLocales(locale), ...getRemoveBgPageLocales(locale) };

	const openAdaptiveMenu = () => {
		setMenuIsVisible(menuIsVisible ? bodyActiveScroll() : bodyHiddenScrollForHeader());
	};

	const closeAdaptiveMenu = () => {
		menuIsVisible && setMenuIsVisible(bodyActiveScroll());
	};

	const closeTabs = () => {
		setActiveTab("");
	};

	const showTab = (tab) => {
		setActiveTab(tab);
	};

	const handleClickOutside = useCallback(
		(event) => {
			if (!event.target.matches(".header") && !event.target.matches(".header *") && !event.target.matches(".menu") && !event.target.matches(".menu *")) {
				closeAdaptiveMenu();
				setActiveTab("");
			}
		},
		//eslint-disable-next-line react-hooks/exhaustive-deps
		[menuIsVisible]
	);

	const resizeWindow = useCallback(() => {
		setShowAdaptiveBtn(false);
		setMenuIsVisible(false);
	}, [setShowAdaptiveBtn]);

	useEffect(() => {
		resizeWindow();
		window.addEventListener("resize", resizeWindow);
		return () => {
			bodyActiveScroll();
			window.removeEventListener("resize", resizeWindow);
		};
	}, [resizeWindow]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	const clickToGetCreditBtn = () => {
		ga.event({
			action: "click to get credits btn",
			params: { page: pathname },
		});
	};

	const [showAuthModal, setShowAuthModal] = useState(false);

	const openAuthModal = (e, type) => {
		e.preventDefault();
		setShowAuthModal(type);
	};

	const [activeToolItem, setActiveToolItem] = useState({ ...removerToolList[0] });

	return (
		<>
			<header className={`header ${style.header} ${style[themeClassName]} ${menuIsVisible ? style.menuOpen : style.menuClose}`} onMouseLeave={closeTabs}>
				<div className={style.controls}>
					<button onClick={openAdaptiveMenu} className={style.burgerBtn} aria-label="open header menu" type="button">
						<HandySvg src={"/header/burger_icon.svg"} width={24} height={24} className={style.burgerIcon} />
						<HandySvg src={"/header/burger_close.svg"} width={24} height={24} className={style.burgerIcon} />
					</button>
					<Link href="/" className={style.logoLink} aria-label="go to main page">
						<HandySvg src={"/header/color_header_logo.svg"} width={196} height={60} className={style.logo} />
					</Link>
					<nav className={style.menu}>
						{authUser && (
							<div className={`${style.accountInfo} ${style.adaptive}`}>
								<Link href="/pricing/" className={style.authLink} onClick={clickToGetCreditBtn}>
									{dictionary["get_credits"] || "Get credits"}
								</Link>
								<div className={style.credits}>
									{authUser?.total_credits > 0 ? authUser?.total_credits : 0}
									<br />
									{dictionary["credits"]}
								</div>
								<Link href="/?workspace=profile" className={style.profileLink}>
									{authUser?.avatar_url ? (
										<Image src={authUser?.avatar_url} width="56" height="56" alt="user avatar" unoptimized={true} style={{ objectFit: "cover" }} />
									) : (
										<>{authUser?.email?.substr(0, 2).toUpperCase()}</>
									)}
								</Link>
							</div>
						)}
						<ul>
							<li className={activeTab === "tools" ? style.open : style.close} onMouseEnter={() => showTab("tools")} onMouseLeave={() => showTab("")}>
								<button onMouseEnter={() => showTab("tools")} onClick={() => (activeTab === "tools" ? showTab(null) : showTab("tools"))} className={style.menuBtn}>
									{dictionary["tools"] || "Tools"}
									<HandySvg src={"/header/menu_arrow.svg"} width={13} height={8} style={{ fill: "none" }} />
								</button>
								<div className={style.subMenuWrapper}>
									<div className={style.subMenu}>
										<SubMenuWrapperItem dictionary={dictionary} setActiveToolItem={setActiveToolItem} title={dictionary["menu_video_features"]} list={removerToolList} type={"video"} />
										<SubMenuWrapperItem dictionary={dictionary} setActiveToolItem={setActiveToolItem} title={dictionary["image_features"]} list={imagesToolList} type={"image"} />
										{activeToolItem && <SubMenuImg dictionary={dictionary} activeToolItem={activeToolItem} />}
									</div>
								</div>
							</li>
							<li>
								<Link href="/application-programming-interface/" className={style.menuBtn} prefetch={false}>
									{"API"}
								</Link>
							</li>
							<li>
								<Link href="/pricing/" className={style.menuBtn} prefetch={false}>
									{dictionary["Pricing"] || "Pricing"}
								</Link>
							</li>
						</ul>
						<LanguageChanger type={"adaptive"} />
					</nav>

					{authUser && (
						<div className={style.accountInfo}>
							<Link href="/pricing/" className={style.authLink} onClick={clickToGetCreditBtn} prefetch={false}>
								{dictionary["get_credits"] || "Get credits"}
							</Link>
							<div className={style.credits}>
								{authUser?.total_credits > 0 ? authUser?.total_credits : 0}
								<br />
								{dictionary["credits"]}
							</div>
							<Link href="/?workspace=profile" className={style.profileLink}>
								{authUser?.avatar_url ? (
									<Image src={authUser?.avatar_url} width="56" height="56" alt="user avatar" unoptimized={true} style={{ objectFit: "cover" }} />
								) : (
									<>{authUser?.email?.substr(0, 2).toUpperCase()}</>
								)}
							</Link>
						</div>
					)}

					{notAuthUser && (
						<div className={`${style.accountInfo} ${style.singIn}`}>
							<Link onClick={(e) => openAuthModal(e, "signIn")} href="/sign-in/" className={`${style.authLink} ${locale === "ru" ? style.authLinkUp : ""}`} prefetch={false}>
								{dictionary["Sign in"]}
							</Link>
						</div>
					)}
					<LanguageChanger themeClassName={themeClassName} />
				</div>
			</header>
			{showAuthModal && <AuthModal closeHandler={() => setShowAuthModal(false)} type={showAuthModal} />}
		</>
	);
}

export default Header;
