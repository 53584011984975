
export const features = [
	{
		name: "background_remover",
		description: "background_remover_description",
		link_url: "/remove-video-bg/",
		result: true,
		isRemover: true,
	},
	{
		name: "interior_redesign",
		description: "page_interior_desc",
		link_url: "/interior-design-planner/",
		result: true
	},
	{
		name: "photo_into_painting",
		description: "get_artwork",
		link_url: "/photo-into-painting/",
		result: true
	},
	{
		name: "erase_replace",
		description: "remove_or_replace",
		link_url: "/erase-and-replace/",
		result: true
	},
	{
		name: "image_generator",
		description: "create_image",
		link_url: "/text-to-image-generator/",
		result: false,
		prompt: "prompt_7"
	},
	{
		name: "page_illustration_h1",
		description: "page_illustration_descriptor",
		link_url: "/illustration-drawing/",
		result: false,
		prompt: "prompt_8"
	},
	{
		name: "Turn photo into video",
		description: "photo_into_video_description",
		link_url: "/turn-photo-into-video/",
		result: false,
		isRemover: true,
	},
	{
		name: "page_editor_h1",
		description: "page_editor_desc",
		link_url: "/editor/",
		result: false
	},
]