import { useState, useEffect, memo } from "react";
import { useRouter } from "next/router";
import styles from "./acceptPayment.module.scss";
import Link from "next/link";
import { HandySvg } from "handy-svg";
import * as ga from "../../lib/ga";

export function AcceptPayment() {
	const { locale, query, pathname } = useRouter();
	const [acceptPaymentOn, setAcceptPaymentOn] = useState(false);

	const acceptPaymentOnOff = () => {
		localStorage.setItem("AcceptPayment", Date.now());
		setAcceptPaymentOn(false);
	};

	useEffect(
		() => {
			let CookiesSession = localStorage.getItem("AcceptPayment");

			const doNeedShowMessage = !CookiesSession;

			if (doNeedShowMessage && !query?.app && locale === "ru" && pathname === "/pricing") {
				setAcceptPaymentOn(true);
			} else if (locale !== "ru") {
				setAcceptPaymentOn(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale, pathname]
	);

	return (
		<>
			{acceptPaymentOn ? (
				<div className={styles.module_wrapper}>
					<button onClick={acceptPaymentOnOff} className={styles.close}></button>{" "}
					<div className={styles.data}>
						<HandySvg src="/ico/acceptPayment.svg" width="67" height="45" />
						<p>Мы принимаем оплату из Вашего региона.</p>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}

export const MemoizedAcceptPayment = memo(AcceptPayment);
