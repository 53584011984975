export const bodyHiddenScroll = () => {
	document.body.style.overflow = "hidden";
	document.body.classList.add("hidden");

	return true;
};

export const activeBodyInRemoveBg = () => {
	document.getElementsByTagName("body")[0].classList.remove("hidden");
	document.body.style.overflowX = "hidden";
	document.body.style.overflowY = "auto";
};

export const bodyActiveScroll = () => {
	document.body.style.overflow = "hidden auto";
	document.getElementsByTagName("body")[0].classList.remove("hidden");

	return false;
};

export const bodyHiddenScrollForHeader = () => {
	document.body.style.overflow = "hidden";
	document.body.classList.add("hidden");
	return true;
};