import Link from "next/link";
import { useState } from "react";
import { useRouter } from "next/router";
import { useStore } from "../../../stores/use_global_state";
import styles from "./Form.module.scss";
import { parseJwt } from "../../../utils/jwt";
import { userAuth } from "../../../pages/api/api";
import { Loading_ellipsis } from "../../loading";

function SignInForm({ dictionary, authHandler }) {
	const { locale } = useRouter();
	const { dispatch } = useStore();

	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");

	const [pass, setPass] = useState("");
	const [passError, setPassError] = useState("");
	const passVisible = false;
	const rememberMe = true;

	const [error, setError] = useState("");

	const [formDisabled, setFormDisabled] = useState(false);

	const validEmail = () => {
		let condition = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
		if (email.length > 0 && !condition.test(email)) {
			setEmailError(dictionary["Invalid e-mail address"]);
			return false;
		}
		if (email.length === 0) {
			setEmailError(dictionary["Required field to fill in"]);
			return false;
		}
		return true;
	};

	const validPass = () => {
		if (pass.length === 0) {
			setPassError(dictionary["Required field to fill in"]);
			return false;
		}
		if (pass.length < 6) {
			setPassError(dictionary["Minimum of 6 characters"]);
			return false;
		}
		return true;
	};

	const formValid = () => {
		return validPass() && validEmail();
	};

	const formSubmit = async (event) => {
		event.preventDefault();
		setFormDisabled(true);
		if (formValid()) {
			let data = {
				username: email.toLowerCase(),
				password: pass,
				locale: locale,
			};
			let response = await userAuth(data);

			if (response.isAxiosError) {
				setFormDisabled(false);
				return setError("Network error");
			}

			if (199 < response.status && response.status < 300) {
				const ParseToken = parseJwt(response.data.token);
				const userData = {
					isLogged: true,
					rememberMe: rememberMe,
					token: response.data.token,
					userData: ParseToken,
				};
				localStorage.setItem("isLogged", JSON.stringify(userData));
				dispatch({
					type: "setAuth",
					userInfo: userData,
					load: false,
				});
				authHandler();
			} else {
				setError(dictionary[response.data.message] ? dictionary[response.data.message] : response.data.message);
			}
		}
		setFormDisabled(false);
	};
	return (
		<form className={styles.form} onSubmit={formSubmit}>
			{error.length ? <div className={styles["request_error"]}>{error}</div> : ""}
			<div className={styles.item}>
				<label htmlFor="e-mail" className={emailError.length ? styles.error : ""}>
					Email
				</label>
				<input
					className={`input ${email ? "not-empty" : ""}`}
					id="e-mail"
					name="e-mail"
					type="text"
					onChange={(e) => setEmail(e.target.value)}
					onFocus={() => {
						setEmailError("");
						setError("");
					}}
					onBlur={validEmail}
					value={email}
					autoComplete="off"
					disabled={formDisabled}
				/>

				{emailError.length > 0 && <div className={styles["email-error"]}>{emailError}</div>}
			</div>
			<div className={styles.item}>
				<label htmlFor="password" className={passError.length ? styles.error : ""}>
					{dictionary["Password"]}
				</label>
				<input
					className={`input ${pass ? "not-empty" : ""}`}
					id="password"
					name="password"
					type={passVisible ? "text" : "password"}
					onFocus={() => {
						setPassError("");
						setError("");
					}}
					onChange={(e) => setPass(e.target.value)}
					onBlur={validPass}
					value={pass}
					disabled={formDisabled}
				/>
				{passError.length > 0 && <div className={styles["password-error"]}>{passError}</div>}
			</div>

			<button className={styles.btn} type="submit" disabled={formDisabled}>
				{formDisabled ? <Loading_ellipsis /> : dictionary["Sign in"]}
			</button>

			<div className={styles["forgot-password"]}>
				<Link href="/forgot-password">{dictionary["Forgot your password?"]}</Link>
			</div>
		</form>
	);
}

export default SignInForm;
