import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import getCommonLocales from "../../locales/locales_common";
import style from "./AuthModal.module.scss";
import SocialMediaAuth from "./SocialMediaAuth/SocialMediaAuth";
import { bodyActiveScroll, bodyHiddenScroll } from "../../utils/bodyToggleHiddenScroll";
import Image from "next/image";
import { HandySvg } from "handy-svg";
import SignUpForm from "./Form/SignUpForm";
import SignInForm from "./Form/SignInForm";
import getErrorLocales from "../../locales/locales_error";
import * as ga from "../../lib/ga";
import ReactDOM from "react-dom";

import eraseImage from "../../public/authModal/bg/erase.png";
import bgremImage from "../../public/authModal/bg/bgremover.png";
import illustratorImage from "../../public/authModal/bg/illustrator.png";
import ttiImage from "../../public/authModal/bg/text-to-img.png";
import interiorredesignImage from "../../public/authModal/bg/interiorredesign.png";
import photoIntopaintingImage from "../../public/authModal/bg/photoIntopainting.png";

const servicesData = {
	mainPage: {
		list: [
			{
				title: {
					en: "AI-Powered Tools",
					ru: "Инструменты, работающие на основе ИИ",
				},
				text: {
					en: "for photo/video editing",
					ru: "для обработки фото/видео",
				},
			},
			{
				title: {
					en: "Free Generations",
					ru: "Бесплатные генерации",
				},
				text: {
					en: "to kickstart your AI journey",
					ru: "для начала вашего путешествия по ИИ",
				},
			},
			{
				title: {
					en: "User-Friendly",
					ru: "Удобный",
				},
				text: {
					en: "nterface",
					ru: "интерфейс",
				},
			},
		],
		isMain: true,
		color: "white",
	},

	Bgremover: {
		title: {
			en: "Edit your videos in a few clicks!  ",
			ru: "Отредактируйте фотографии одним нажатием.",
		},
		description: {
			en: "Sign up and remove or replace your video’s background! Use our editor also to overlay music, text and other improvements!",
			ru: "Регистрируйся и получи 4 бесплатные генерации. Удаляйте лишние объекты с фото или примени бесплатные кредиты в любом из наших инструментов.",
		},
		imgUrl: bgremImage,
		bg: `#6371DE`,
		color: "white",
	},

	"Erase&Replace": {
		title: {
			en: "Edit your photos with a click!",
			ru: "Редактируй свои видео за пару кликов",
		},
		description: {
			en: "Sign up and get 4 free generations! Let Erase & Replace enhance your photos or use free credits in any other tools",
			ru: "Регистрируйся и удаляй или замещай фон твоих видео. Используй наш редактор для добавления музыки, текста и других улучшений",
		},
		imgUrl: eraseImage,
		bg: `linear-gradient(180deg, #9997C6 14.5%, #73515F 59.57%, #44333B 109.58%)`,
		color: "white",
	},

	illustratorDrawing: {
		title: {
			en: "Create expressive illustrations in a few clicks!",
			ru: "Создавай потрясающие иллюстрации всего несколькими кликами.",
		},
		description: {
			en: "Sign up for free, receive 4 generations to kickstart your creative journey or use them across any of our tools",
			ru: "Регистрируйся бесплатно и воспользуйся четырьмя бесплатными генерациями для начала своего творческого путешествия, либо примени их в любом из наших инструментов.",
		},
		imgUrl: illustratorImage,
		bg: `linear-gradient(0deg, #212321 21.75%, #485C5B 100%)`,
		color: "white",
	},

	TextToImg: {
		title: {
			en: "Sign up and bring ideas to life! ",
			ru: "Регистрируйся и воплощай свои идеи в жизнь!",
		},
		description: {
			en: "Enjoy 4 free generations to kickstart your creative journey or use them across any of our tools",
			ru: "Регистрируйся бесплатно и воспользуйся четырьмя бесплатными генерациями для начала своего творческого путешествия, либо примени их в любом из наших инструментов.",
		},
		imgUrl: ttiImage,
		bg: `linear-gradient(0deg, #977867 20.1%, #9BACBF 53.66%, #D1E3E3 99.64%)`,
		color: "black",
	},

	InteriorRedesign: {
		title: {
			en: "Sign up and redesign your interior!  ",
			ru: "Регистрируйтесь и измените дизайн интерьера",
		},
		description: {
			en: "You will receive 4 free generations to start your design journey or use it in any other tools",
			ru: "Вы получите 4 бесплатные генерации, чтобы начать свое дизайнерское путешествие или используй их в любом из наших инструментов.",
		},
		imgUrl: interiorredesignImage,
		bg: "linear-gradient(1deg, #4B3F32 3.66%, #B7A696 94.68%)",
		color: "white",
	},

	PhotoIntoPainting: {
		title: {
			en: "Sign up and turn photos into masterpieces! ",
			ru: "Присоединяйся и преврати свои фото в произведения искусства ",
		},
		description: {
			en: "Enjoy 4 free generations to kickstart your creative journey or use them across any of our tools",
			ru: "Воспользуйся 4 бесплатными генерациями для начала своего творческого путешествия, либо используй их в рамках любого из наших  инструментов",
		},
		imgUrl: photoIntopaintingImage,
		bg: "linear-gradient(143deg, #B0DFE7 15.82%, #53BAC9 90.63%)",
		color: "black",
	},
};

function AuthModal({ closeHandler = () => {}, serviceName = "", testValue = "", type }) {
	const [step, setStep] = useState("form"); // form | message
	const [method, setMethod] = useState("social"); // social | email
	const [signType, setSignType] = useState(type ? type : "signUp"); // signIn | signUp

	const { locale } = useRouter();
	const isRu = locale === "ru";

	const dictionary = { ...getCommonLocales(locale), ...getErrorLocales(locale) };

	const changeMethodHandler = () => {
		setMethod("email");
	};

	const changeSignType = () => {
		setSignType(() => {
			return signType === "signIn" ? "signUp" : "signIn";
		});
	};

	const backHandler = () => {
		setMethod("social");
	};

	useEffect(() => {
		bodyHiddenScroll();

		return () => bodyActiveScroll();
	}, []);

	const registerHandler = () => {
		ga.event({
			action: "Sign up",
			params: { page: serviceName, method: "Bgrem", testValue: testValue?.length > 0 ? testValue : "" },
		});
		setStep("message");
	};

	const authHandler = () => {
		ga.event({
			action: "Sign in",
			params: { page: serviceName, method: "Bgrem", testValue: testValue?.length > 0 ? testValue : "" },
		});
		closeHandler();
	};

	const serviceData = servicesData[serviceName] ? servicesData[serviceName] : servicesData["mainPage"];

	return ReactDOM.createPortal(
		<div className={style.overlay}>
			<div className={style.closeBack} onClick={closeHandler}></div>
			<div className={style.wrapper} id="auth-modal">
				{serviceData.isMain && (
					<div className={`${style.collage} ${style.isMain} ${style[serviceData.color]}`}>
						<div className={`${style.list} `}>
							{serviceData.list.map((item, index) => {
								return (
									<p key={index}>
										<b>{item.title[isRu ? "ru" : "en"]}</b> {item.text[isRu ? "ru" : "en"]}
									</p>
								);
							})}
						</div>
					</div>
				)}
				{!serviceData.isMain && (
					<div className={`${style.collage} ${style[serviceData.color]}`} style={{ background: serviceData["bg"], backgroundRepeat: "no-repeat", backgroundSize: `100%` }}>
						<div className={`${style.collageText}`}>
							<h3>{serviceData["title"][isRu ? "ru" : "en"]}</h3>
							<p>{serviceData["description"][isRu ? "ru" : "en"]}</p>
						</div>
						<Image className={`${style.collageImage}`} src={serviceData.imgUrl} placeholder="blur" sizes="100vw" width={100} height={100} alt="Collage" quality={99} style={{ objectFit: "contain" }} />
					</div>
				)}

				<div className={`${style.interactive} ${method === "email" ? style.email : ""}`}>
					{method === "email" && step !== "message" && (
						<button className={style.backBtn} onClick={backHandler}>
							<HandySvg src={"/illustration_service/next.svg"} width={24} height={24} fill={"transparent"} />
						</button>
					)}

					<button className={style.closeBtn} onClick={closeHandler}>
						<HandySvg width="15" height="15" src={`/editor/close_icon.svg`} />
					</button>

					{signType === "signUp" && (
						<div>
							{step !== "message" && (
								<div className={style.text}>
									<p className={style.title}>{dictionary["please_sign_in_to_continue"]}</p>
									<button id="open-sign-in" onClick={changeSignType} className={style.subtitle}>
										{dictionary["Already have an account?"]} {dictionary["Sign in"]}
									</button>
								</div>
							)}
							{method == "social" && step === "form" && (
								<SocialMediaAuth action={signType} dictionary={dictionary} changeMethodHandler={changeMethodHandler} locale={locale} serviceName={serviceName} testValue={testValue} />
							)}
							{method === "email" && step === "form" && <SignUpForm dictionary={dictionary} registerHandler={registerHandler} />}
							{step === "message" && (
								<div className={style.messageContainer}>
									<p
										className={style.title}
										dangerouslySetInnerHTML={{
											__html: `${dictionary["Thank you your application for registration has been accepted"]}`,
										}}
									/>
									<p
										className={style.message}
										dangerouslySetInnerHTML={{
											__html: `${dictionary["You should receive an email with a confirmation link Please click on the link to complete your registration It will be active for 24 hours"]}`,
										}}
									/>
									<button className={style.messageBtn} onClick={closeHandler}>
										OK
									</button>
								</div>
							)}
						</div>
					)}

					{signType === "signIn" && (
						<div>
							<div className={style.text}>
								<p className={style.title}>{dictionary["please_sign_up_to_continue"]}</p>
								<button id="sign-in-btn" onClick={changeSignType} className={style.subtitle}>
									{dictionary["Don't have an account?"]} {dictionary["Sign up"]}
								</button>
							</div>
							{method == "social" && (
								<SocialMediaAuth action={signType} dictionary={dictionary} changeMethodHandler={changeMethodHandler} locale={locale} serviceName={serviceName} testValue={testValue} />
							)}
							{method === "email" && <SignInForm dictionary={dictionary} authHandler={authHandler} />}
						</div>
					)}
				</div>
				<button className={style.lightCloseBtn} onClick={closeHandler}>
					<HandySvg width="15" height="15" src={`/editor/close_icon.svg`} />
				</button>
			</div>
		</div>,
		document.getElementById("portal")
	);
}

export default AuthModal;
