import { useState } from "react";
import { useRouter } from "next/router";
import styles from "./Form.module.scss";
import { userRegister } from "../../../pages/api/api";
import { Loading_ellipsis } from "../../loading";

function SignUpForm({ dictionary, registerHandler }) {
	const { locale } = useRouter();

	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");

	const [pass, setPass] = useState("");
	const [passError, setPassError] = useState("");
	const passVisible = false;

	const [passConfirm, setPassConfirm] = useState("");
	const [passConfirmError, setPassConfirmError] = useState("");
	const passConfirmVisible = false;

	const [agree, setAgree] = useState(false);
	const [agreeError, setAgreeError] = useState("");

	const [error, setError] = useState("");

	const [formDisabled, setFormDisabled] = useState(false);

	const validEmail = () => {
		let condition = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
		if (email.length > 0 && !condition.test(email)) {
			setEmailError(dictionary["Invalid e-mail address"]);
			return false;
		}
		if (email.length === 0) {
			setEmailError(dictionary["Required field to fill in"]);
			return false;
		}
		return true;
	};

	const validPass = () => {
		if (pass.length === 0) {
			setPassError(dictionary["Required field to fill in"]);
			return false;
		}
		if (pass.length < 6) {
			setPassError(dictionary["Minimum of 6 characters"]);
			return false;
		}
		return true;
	};

	const validPassConfirm = () => {
		if (passConfirm.length > 0 && passConfirm !== pass) {
			setPassConfirmError(dictionary["Passwords don't match"]);
			return false;
		}
		if (passConfirm.length < 1) {
			setPassConfirmError(dictionary["Required field to fill in"]);
			return false;
		}
		return true;
	};

	const validAgree = () => {
		if (!agree) {
			setAgreeError(dictionary["You need to give consent"]);
			return false;
		}
		return true;
	};

	const formValid = () => {
		let examinationEmail = validEmail();
		let examinationPass = validPass();
		let examinationPassConfirm = validPassConfirm();
		let examinationAgree = validAgree();

		return examinationEmail && examinationPass && examinationPassConfirm && examinationAgree;
	};

	const formSubmit = async (event) => {
		event.preventDefault();
		setFormDisabled(true);
		if (formValid()) {
			let data = {
				username: email.toLowerCase(),
				password: pass,
				password2: passConfirm,
				locale: locale,
				redirect_url: window.location.href,
			};

			let response = await userRegister(data);

			if (response.isAxiosError) {
				setFormDisabled(false);
				return setError("Network error");
			}

			if (199 < response.status && response.status < 300) {
				registerHandler();
			} else {
				setError(response.data.message === '"username" field is required' ? dictionary["A user with that username already exists."] : response.data.message);
			}
		}
		setFormDisabled(false);
	};
	return (
		<form className={styles.form} onSubmit={formSubmit}>
			{error?.length !== 0 && (
				<div id="request-error" className={styles["request_error"]}>
					{error}
				</div>
			)}
			<div className={styles.item}>
				<label htmlFor="e-mail" className={emailError.length ? styles.error : ""}>
					Email
				</label>
				<input
					className={`input ${styles.email ? "not-empty" : ""}`}
					id="e-mail"
					name="e-mail"
					type="text"
					onChange={(e) => setEmail(e.target.value)}
					onFocus={() => setEmailError("")}
					onBlur={validEmail}
					value={email}
					autoComplete="off"
					disabled={formDisabled}
				/>

				{emailError.length > 0 && (
					<div id="email-error" className={styles["email-error"]}>
						{emailError}
					</div>
				)}
			</div>
			<div className={styles.item}>
				<label htmlFor="password" className={passError.length ? styles.error : ""}>
					{dictionary["Password"]}
				</label>
				<input
					className={`input ${pass ? styles["not-empty"] : ""}`}
					id="password"
					name="password"
					type={passVisible ? "text" : "password"}
					onFocus={() => setPassError("")}
					onChange={(e) => setPass(e.target.value)}
					onBlur={validPass}
					value={pass}
					disabled={formDisabled}
				/>
				{passError.length > 0 && (
					<div id="password-error" className={styles["password-error"]}>
						{passError}
					</div>
				)}
			</div>
			<div className={styles.item}>
				<label htmlFor="confirm_password" className={passConfirmError.length ? "error" : ""}>
					{dictionary["Confirm password"]}
				</label>
				<input
					className={`input ${passConfirm ? "not-empty" : ""}`}
					id="confirm_password"
					name="password"
					type={passConfirmVisible ? "text" : "password"}
					onChange={(e) => setPassConfirm(e.target.value)}
					onFocus={() => setPassConfirmError("")}
					onBlur={validPassConfirm}
					value={passConfirm}
					disabled={formDisabled}
				/>

				{passConfirmError.length > 0 && (
					<div id="confirm-error" className={styles["password-error"]}>
						{passConfirmError}
					</div>
				)}
			</div>
			<div className={styles.item}>
				<div className={styles.agreement}>
					<input
						id="agree"
						className={styles["agreement-checkbox"]}
						type="checkbox"
						checked={agree}
						onChange={() => {
							setAgree(!agree);
							validAgree();
						}}
						disabled={formDisabled}
					/>
					<span
						className={styles["agreement-text"]}
						dangerouslySetInnerHTML={{
							__html: `${dictionary["You agree with our Privacy Policy and Terms of use and consent to receive personalized emails about our products and services See our Privacy Policy for details"]}`,
						}}
					/>
				</div>
				{!agree ? <div className={styles["agreement-error"]}>{agreeError}</div> : ""}
			</div>

			<button id="submit-btn" className={styles.btn} type="submit" disabled={formDisabled}>
				{formDisabled ? <Loading_ellipsis /> : dictionary["Sign up"]}
			</button>
		</form>
	);
}

export default SignUpForm;
