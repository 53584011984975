import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { HandySvg } from "handy-svg";
import Image from "next/image";
import { languages } from "../../../constants/languages";
import style from "./languageChanger.module.scss";

function LanguageChanger({ type = "desktop", themeClassName }) {
	const [openList, setOpenList] = useState(false);
	const router = useRouter();
	const { pathname, asPath, query, locale } = router;
	const [activeLanguageItem, setActiveLanguageItem] = useState(languages.find((lang) => lang.locale === locale));

	useEffect(() => {
		const activeLocale = languages.find((lang) => lang.locale === locale);
		setActiveLanguageItem(activeLocale);
		setOpenList(false);
	}, [locale]);

	const setCookie = (locale) => (document.cookie = `NEXT_LOCALE=${locale}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`);

	const languageSwitch = (language) => {
		setCookie(language.locale);
		router.push({ pathname, query }, asPath, { locale: language.locale });
	};

	const handleClickOutside = useCallback(
		(event) => {
			if (!event.target.matches(`.${style.language}`) && !event.target.matches(`.${style.language} *`) && openList) setOpenList(false);
		},
		[openList, setOpenList]
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={`${style.language} ${style[themeClassName]} ${openList ? style.open : style.close} ${style[type]}`}>
			<button className={style.langActive} onClick={() => setOpenList(!openList)}>
				<HandySvg src={`/locales/${activeLanguageItem.img}.svg`} width={24} height={24} fill={"transparent"} />
				<span>{activeLanguageItem.abbreviation}</span>
				<HandySvg src={"/header/menu_arrow.svg"} width={12} height={6} style={{ fill: "none" }} className={style.arrowIcon} />
			</button>
			<div className={style.listWrapper}>
				<ul className={style.langList}>
					{languages
						.filter((item) => item.language !== activeLanguageItem.language)
						.map((item) => (
							<li key={item.language} onClick={() => languageSwitch(item)} className={style.langItem}>
								{item.language}
							</li>
						))}
				</ul>
			</div>
		</div>
	);
}

export default LanguageChanger;
