import { useEffect } from "react";

const useClickOutside = (ref, callback, resizeCallbackEnabled = true) => {
	useEffect(() => {
		const clickOutsideListener = (e) => {
			if (!ref.current || ref.current.contains(e.target)) {
				return;
			}
			callback(e);
		};

		document.addEventListener("mousedown", clickOutsideListener);
		document.addEventListener("touchstart", clickOutsideListener);
		if (resizeCallbackEnabled) window.addEventListener("resize", callback);

		return () => {
			document.removeEventListener("mousedown", clickOutsideListener);
			document.removeEventListener("touchstart", clickOutsideListener);

			if (resizeCallbackEnabled) window.addEventListener("resize", callback);
		};
	}, [ref, callback]);
};

export default useClickOutside;
