import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Header from "./header/header";
import Footer from "./footer/footer";
import { MemoizedCookies } from "./cookies";
import { GlobalContext } from "../stores/use_global_state";
import { checkAvailability } from "../pages/api/api.js";
import { MemoizedAcceptPayment } from "./acceptPayment/acceptPayment";
import * as ga from "../lib/ga";
import Question from "./question/question";
import AllPageBannerPromo from "./affiliate-program/AllPageBanner/AllPageBannerPromo";
import HalloweenPromo from "./promo/HalloweenPromotion.jsx";

const DynamicError = dynamic(() => import("./error/error"), { ssr: false });
const PromotionTimer = dynamic(() => import("./error/promotionTimer.jsx"), { ssr: false });

export default function Layout({ children, mainClassName = "", themeClassName = "", cookies = null }) {
	const router = useRouter();
	const { pathname } = router;
	const { state, dispatch } = useContext(GlobalContext);
	const [showAdaptiveBtn, setShowAdaptiveBtn] = useState(true);
	const [showFooter, setShowFooter] = useState(true);

	const editorAdaptive =
		((pathname.split("/").includes("remove-video-bg") || pathname.split("/").includes("turn-photo-into-video")) && (state?.previewImg || state.video)) ||
		(pathname.split("/").includes("erase_and_replace") && state.eraseAndReplaceImg.length > 0) ||
		(pathname === "/interior-design-planner" && state.interiorRedesignImg) ||
		(pathname === "/photo-into-painting" && state.styleTransImg);

	useEffect(
		() => {
			const startCheckAvailability = async () => {
				let check = await checkAvailability();
				if (check.maintenance) {
					dispatch({ type: "setVideoError", error_msg: "isTemporarilyUnavailable", error_code: "unavailable" });
					router.push({ pathname: "/error/" });
					return false;
				}
			};
			startCheckAvailability();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() => {
			setShowFooter(
				state?.interiorRedesignImg === null &&
					state?.styleTransImg === null &&
					!pathname.split("/").includes("sign-in") &&
					!pathname.split("/").includes("sign-up") &&
					!pathname.split("/").includes("thank") &&
					!pathname.split("/").includes("forgot-password") &&
					!(router.query?.screen === "full" && state.editorImage)
			);

			if (pathname === "/") {
				setShowAdaptiveBtn(false);
			}

			const handleScroll = () => {
				if (pathname === "/") {
					const scrolledFromTop = window.pageYOffset;
					scrolledFromTop > 600 ? setShowAdaptiveBtn(true) : setShowAdaptiveBtn(false);
				}
			};

			window.addEventListener("scroll", handleScroll);
			return () => window.removeEventListener("scroll", handleScroll);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const checkSignInFromRemoveBg = () => {
		if (localStorage.getItem("signInFromRemoveBg") && (pathname === "/" || (pathname === "/sign-in") | (pathname === "/sign-up") || pathname === "/thank") && cookies === "a") {
			return;
		}
		if (localStorage.getItem("signInFromRemoveBg") && pathname === "/remove-video-bg" && cookies === "a") {
			ga.event({ action: "AB return to remove bg a", params: { event_category: `Bgremover page a` } });
			localStorage.removeItem("signInFromRemoveBg");
		}
		if (pathname !== "/" && pathname !== "/sign-in" && pathname !== "/sign-up" && pathname !== "/remove-video-bg" && pathname !== "/thank") {
			localStorage.removeItem("signInFromRemoveBg");
		}
	};

	useEffect(
		() => {
			checkSignInFromRemoveBg();
			setShowFooter(
				state?.interiorRedesignImg === null &&
					state?.styleTransImg === null &&
					state.eraseAndReplaceImg.length < 1 &&
					!pathname.split("/").includes("sign-in") &&
					!pathname.split("/").includes("sign-up") &&
					!pathname.split("/").includes("thank") &&
					!pathname.split("/").includes("forgot-password") &&
					!(router.query?.screen === "full" && state.editorImage)
			);
		},
		//eslint-disable-next-line react-hooks/exhaustive-deps
		[router.query, state]
	);

	const [showCountdown, setShowCountdown] = useState(false);

	useEffect(
		() => {
			if (state?.promotion) {
				return setShowCountdown(true);
			}
			if (!state?.promotion) {
				return setShowCountdown(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.promotion]
	);

	useEffect(
		() => {
			const storedTime = localStorage.getItem("countdownChristmasTime");
			const storedTimestamp = localStorage.getItem("countdownChristmasTimestamp");
			const timerExpired = localStorage.getItem("timerChristmasExpired");

			if (storedTime && storedTimestamp && !timerExpired && state?.promotion !== null) {
				const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
				const remainingTime = Math.max(0, parseInt(storedTime) - elapsedTime);

				if (remainingTime < 0) {
					return setShowCountdown(false);
				}
				return setShowCountdown(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const isError = state?.error_msg || state?.error_limit_msg;
	const isPromotion = state?.error_code === "promotion" && !localStorage?.getItem("timerChristmasExpired") ? true : false;

	return (
		<>
			{!(router?.query?.screen === "full" && state?.editorImage) && !state?.video && !state?.job && <AllPageBannerPromo />}
			<div className="AppWrapper" id="top">
				{isError || isPromotion ? <DynamicError /> : null}
				{!(router.query?.screen === "full" && state.editorImage) && <Header setShowAdaptiveBtn={setShowAdaptiveBtn} themeClassName={themeClassName} cookies={cookies} />}
				<main className={`main ${mainClassName} ${editorAdaptive ? "editor-adaptive" : ""} ${router.query?.screen === "full" && state.editorImage ? "no-paddingTop" : ""}`}>{children}</main>
				{showFooter && <Footer editorAdaptive={editorAdaptive} showAdaptiveBtn={showAdaptiveBtn} />}
				<MemoizedCookies />
				<MemoizedAcceptPayment />
				{!showCountdown && <Question />}
				{showCountdown && <PromotionTimer locale={router.locale} close={() => setShowCountdown(false)} />}
				<HalloweenPromo />
			</div>
		</>
	);
}
