import style from "./AllPageBannerPromo.module.scss";
import { useRouter } from "next/router";
import Link from "next/link";
import { HandySvg } from "handy-svg";
import { useContext, useEffect, useState } from "react";
import { getCookieByName } from "../../../utils/getCookie";
import { GlobalContext } from "../../../stores/use_global_state";
import * as ga from "../../../lib/ga";
import getCommonLocales from "../../../locales/locales_common";

export const CountdownTimer = ({ close = () => {}, isRu = false }) => {
	const initialTime = 60 * 60 * 1.3;
	const [time, setTime] = useState(initialTime);

	useEffect(
		() => {
			const storedTime = localStorage.getItem("countdownPromoBannerTime");
			const storedTimestamp = localStorage.getItem("countdownPromoBannerTimestamp");

			if (storedTime && storedTimestamp) {
				const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
				const remainingTime = Math.max(0, parseInt(storedTime) - elapsedTime);

				if (remainingTime < 0) {
					localStorage.setItem("promoBannerIsCloseNew", "true");
					close();
					setTime(0);
				}
				setTime(remainingTime);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() => {
			const timer = setInterval(() => {
				setTime((prevTime) => {
					const newTime = prevTime - 1;
					localStorage.setItem("countdownPromoBannerTime", newTime);
					localStorage.setItem("countdownPromoBannerTimestamp", Date.now().toString());

					if (newTime < 0) {
						localStorage.setItem("promoBannerIsCloseNew", "true");
						clearInterval(timer);
						close();
						return 0;
					}

					return newTime;
				});
			}, 1000);

			return () => clearInterval(timer);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const formatTime = (seconds) => {
		const hh = Math.floor(seconds / 3600);
		const mm = Math.floor((seconds % 3600) / 60);
		const ss = seconds % 60;
		return (
			<div className={style.timer}>
				<span data-label={isRu ? "часы" : "hours"}>{String(hh).padStart(2, "0")}</span>:<span data-label={isRu ? "минуты" : "minutes"}>{String(mm).padStart(2, "0")}</span>:
				<span data-label={isRu ? "секунды" : "seconds"}>{String(ss).padStart(2, "0")}</span>
			</div>
		);
	};

	return <>{formatTime(time)}</>;
};

function AllPageBannerPromo() {
	const router = useRouter();
	const { locale } = router;
	const dictionary = getCommonLocales(locale);
	const isRu = locale === "ru";
	const [promoBannerIsOpen, setPromoBannerIsOpen] = useState(false);
	const [test, setTest] = useState(null);
	const [stickerUrl, setStickerUrl] = useState(null);
	const { state } = useContext(GlobalContext);

	useEffect(() => {
		const testType = getCookieByName("a_b_test");
		setTest(testType === "c" ? "b" : testType);
	}, []);

	const title = dictionary["bonuse_15_credits_pay_today"] || "Get +15 Credits with Every Payment Today";
	const description = dictionary["subscribe_today_enter_promo_code"] || "Subscribe today and then enter promo code Boost15 in your personal account";
	const btn = dictionary["explore_plans"] || "Explore plans";

	useEffect(() => {
		let affiliateBannerIsClose = localStorage.getItem("promoBannerIsCloseNew");
		if (!affiliateBannerIsClose && (state?.userInfo === null || state?.accountData?.plan === "free")) setPromoBannerIsOpen(true);
	}, [state]);

	useEffect(() => {
		if (state?.userInfo !== null && state?.accountData !== null && state?.accountData?.plan !== "free") {
			localStorage.setItem("promoBannerIsCloseNew", "true");
			setPromoBannerIsOpen(false);
		}
	}, [state]);

	const close = (event) => {
		event?.stopPropagation();
		localStorage.setItem("promoBannerIsCloseNew", "true");
		setPromoBannerIsOpen(false);
	};

	useEffect(() => {
		if (test === null) return;
		const url = `/affiliate_program/sticker_${test}_${isRu ? "ru" : "en"}.svg`;
		setStickerUrl(url);
	}, [test, isRu]);

	const goToPricingPage = (event) => {
		event?.preventDefault();

		ga.event({
			action: `click to explore plans button`,
			params: {
				testValue: test,
				event_callback: () => {
					router.push({ pathname: "/pricing/" });
				},
			},
		});
	};

	if (!promoBannerIsOpen) return <></>;

	return (
		<div className={style.wrapper}>
			<div className={`${style.container} ${style[test]}`}>
				{stickerUrl !== null && (
					<>
						<button className={style.closeBtn} aria-label="close" onClick={close}>
							<HandySvg width="12" height="12" src={`/editor/close_icon.svg`} />
						</button>
						<HandySvg fill="#D821BA" className={style.image} src={stickerUrl} alt={title} width={128} height={128} />
						<div className={style.text}>
							<h4 className={style.title}>{title}</h4>
							<p className={style.description}>{description}</p>
						</div>
						<div className={style.timerWrapper}>
							<CountdownTimer {...{ isRu, close }} />
							<Link href={"/pricing/"} className={style.button} onClick={goToPricingPage}>
								<span>{btn}</span>
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default AllPageBannerPromo;
