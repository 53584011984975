export const languages = [
  {
    language: "English",
    abbreviation: "ENG",
    img: "us",
    locale: "en",
  },
  {
    language: "Español",
    abbreviation: "ESP",
    img: "mx",
    locale: "es",
  },
  {
    language: "Português",
    abbreviation: "POR",
    img: "br",
    locale: "pt",
  },
  {
    language: "Français",
    abbreviation: "FRA",
    img: "fr",
    locale: "fr",
  },
  {
    language: "中文",
    abbreviation: "CHI",
    img: "cn",
    locale: "zh",
  },
  {
    language: "한국어",
    abbreviation: "KOR",
    img: "kr",
    locale: "ko",
  },
  {
    language: "Русский",
    abbreviation: "RUS",
    img: "ru",
    locale: "ru",
  },
  {
    language: "German",
    abbreviation: "GER",
    img: "de",
    locale: "de",
  },
  {
    language: "Japanese",
    abbreviation: "JAP",
    img: "ja",
    locale: "ja",
  },
  {
    language: "العربية",
    abbreviation: "العربية",
    img: "ar",
    locale: "ar",
  },
];