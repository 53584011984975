import React, { useState, useEffect } from "react";
import styles from "./HalloweenPromo.module.scss";
import ReactDOM from "react-dom";
import Image from "next/image";
import { HandySvg } from "handy-svg";
import Link from "next/link";
import { useRouter } from "next/router";

const HalloweenPromo = () => {
	const [showModal, setShowModal] = useState(false);
	const router = useRouter();

	const isHalloweenPeriod = () => {
		const today = new Date();
		const currentYear = today.getFullYear();

		// One month before Halloween
		const startDate = new Date(currentYear, 8, 30); // September 30th

		// Day after Halloween (when we stop showing)
		const endDate = new Date(currentYear, 10, 1); // November 1st

		return today >= startDate && today < endDate;
	};

	const isHalloweenPage = () => {
		return router?.query?.landings?.includes("halloween-art-generator");
	};

	useEffect(() => {
		// Don't show if we're already on the Halloween page
		if (isHalloweenPage()) {
			console.log("isHalloweenPage");
			return close();
		}

		// Check if we're in the Halloween period
		if (!isHalloweenPeriod()) {
			console.log("isHalloweenPeriod");
			return;
		}

		// Check if modal was already shown in this session
		const wasShown = sessionStorage.getItem("halloweenPromoShown");
		console.log("isHalloweenWasShown");

		if (!wasShown) {
			setShowModal(true);
		}
	}, [router.pathname]);

	const close = () => {
		// Mark modal as shown in session storage
		sessionStorage.setItem("halloweenPromoShown", "true");
		setShowModal(false);
	};

	// Don't render anything if modal shouldn't be shown
	if (!showModal) {
		return null;
	}

	return ReactDOM.createPortal(
		<div className={`${styles.modalWrapperPortal}`}>
			<div onClick={close} className={styles.modalCloseBg} />
			<div className={styles.container}>
				<button className={styles.close} onClick={close}></button>

				<Image className={styles.scene} width={680} height={446} src="/promotion/halloween.png" alt={"Halloween home"} />
				<div className={styles.content}>
					<div className={styles.text}>
						<h2 className={styles.title}>Boo! Halloween is creeping closer!</h2>
						<p className={styles.subtitle}>Conjure up some spooky art with our generator</p>
					</div>
					<Link href="/landings/halloween-art-generator/" className={styles.discoverButton}>
						Discover
						<HandySvg src="/promotion/icon/bat.svg" width="24" height="24" />
					</Link>
				</div>
			</div>
		</div>,
		document.getElementById("portal")
	);
};

export default HalloweenPromo;
