import localesEn from "./en/remove_bg_page.json";
import localesEs from "./es/remove_bg_page.json";
import localesFr from "./fr/remove_bg_page.json";
import localesKo from "./ko/remove_bg_page.json";
import localesRu from "./ru/remove_bg_page.json";
import localesZh from "./zh/remove_bg_page.json";
import localesPt from "./pt/remove_bg_page.json";
import localesDe from "./de/remove_bg_page.json";
import localeJa from "./ja/remove_bg_page.json";

export default function getRemoveBgPageLocales(locale) {
	switch (locale) {
		case "pt":
			return localesPt;
		case "es":
			return localesEs;
		case "ru":
			return localesRu;
		case "fr":
			return localesFr;
		case "ko":
			return localesKo;
		case "zh":
			return localesZh;
		case "de":
			return localesDe;
		case "ja":
			return localeJa;
		default:
			return localesEn;
	}
}
